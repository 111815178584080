import VueRouter from "vue-router";
import Distributor from "../components/Distributor"
import Order from "../components/Order"
import Home from "../Home"
import Login from "../Login"
import Index from "@/components/Index.vue";

const router = new VueRouter({
    routes:[
        {
            path: '/',
            component: Home,
            redirect: '/home/index',
            meta:{ requiresAuth : true}
        },
        {
            path: '/home',
            component: Home,
            children: [
                {
                    path: 'index',
                    component: Index,
                    meta:{ requiresAuth : true}
                },
                {
                    path: 'distributor',
                    component: Distributor
                },
                {
                    path: 'order',
                    component: Order
                },
            ],
            meta:{ requiresAuth : true}
        },
        {
            path: '/login',
            component: Login
        }
    ]
})
export default router;