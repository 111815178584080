import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from "vue-router";
import router from "./router/router"
import axios from "axios";

Vue.config.productionTip = true
Vue.prototype.$axios = axios
Vue.prototype.$httpUrl = "https://china-boradnet-api.zhxxh.cn/"
Vue.use(ElementUI)
Vue.use(VueRouter)

axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')) {
            config.headers.Authorization = localStorage.getItem('token');
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    });

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");//从vuex拿到对应token
    //筛选需要传token的路由，匹配route里面需要登录的路径，如果匹配到就是true
    //to:去哪一个路由
    //遍历 $route.matched 来检查路由记录中的 meta 字段。
    //some() 方法测试数组中的某些元素是否通过了指定函数的测试。
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //根据token是否有，判断是否需要跳转到登录页面
        if (token) {//如果token有
            next()
        } else {
            next({path: '/login'})
        }
    } else {
        next();
    }

})

var vm = new Vue({
    el: '#app',
    render: h => h(App),
    router
});

//  RESPONSE 响应异常拦截
axios.interceptors.response.use(res => {
    if (res.data.code === 8006) {
        vm.$router.replace({
            path: '/login'
        })
        return Promise.reject(new Error("登录超时"));
    }
    if (res.data.status === false) {
        return Promise.reject(new Error(res.data.msg));
    }
    return res;
}, err => {
    return Promise.reject(err)
})