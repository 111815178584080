<template>
  <div id="index">
    <el-row>
      <el-col :span="6">
        <el-card class="box-card-num">
          <div slot="header">
            <span>今日订单数</span>
          </div>
          <div class="dataNum">
            {{ todayOrderNum }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card-num">
          <div slot="header">
            <span>昨日订单数</span>
          </div>
          <div class="dataNum">
            {{ yesterdayOrderNum }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card-num">
          <div slot="header">
            <span>本周订单数</span>
          </div>
          <div class="dataNum">
            {{ weekOrderNum }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card-num">
          <div slot="header">
            <span>本月订单数</span>
          </div>
          <div class="dataNum">
            {{ monthOrderNum }}
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-card class="box-card-table">
          <canvas id="thisWeek"></canvas>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card-table">
          <canvas id="nextWeek"></canvas>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Schart from 'schart.js';

export default {
  name: "Index",
  data() {
    return {
      todayOrderNum: 0,
      yesterdayOrderNum: 0,
      weekOrderNum: 0,
      monthOrderNum: 0,
      thisWeekData: [0, 0, 0, 0, 0, 0],
      lastWeekData: [0, 0, 0, 0, 0, 0],
    }
  },
  mounted() {
    let thisWeekData
    let lastWeekData
    this.$axios.get(this.$httpUrl + 'backend/index/index.json')
        .then(res => {
          console.log(res.data.data)
          this.todayOrderNum = res.data.data.todayOrderNum
          this.yesterdayOrderNum = res.data.data.yesterdayOrderNum
          this.weekOrderNum = res.data.data.weekOrderNum
          this.monthOrderNum = res.data.data.monthOrderNum
          this.thisWeekData = res.data.data.thisWeekData
          this.lastWeekData = res.data.data.lastWeekData
          let options1 = {
            type: 'bar',
            title: {
              text: '本周订单数',
            },
            bgColor: '#fbfbfb',
            labels: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            datasets: [{
              label: '订单数',
              data: this.thisWeekData
            }]
          }
          new Schart('thisWeek', options1);

          let options2 = {
            type: 'bar',
            title: {
              text: '上周订单数',
            },
            bgColor: '#fbfbfb',
            labels: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            datasets: [{
              label: '订单数',
              data: this.lastWeekData
            }]
          }
          new Schart('nextWeek', options2);
        }, err => {
          this.$message.error(err.message)
        })
  }
}
</script>

<style scoped>
.dataNum {
  font-size: xxx-large;
  color: dodgerblue;
  margin-top: 20px;
}

.box-card-num {
  margin-left: 20px;
  height: 30%;
}

.box-card-table {
  margin-top: 5%;
  margin-left: 20px;
  height: 50%;
}

#thisWeek {
  width: 100% !important;
}

#nextWeek {
  width: 100% !important;
}
</style>