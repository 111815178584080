<template>
  <div>
    <div id="header">
      <el-row>
        <el-col :span="4">名称:
          <el-input class="filter" v-model="tableFilterData.name" clearable></el-input>
        </el-col>
        <el-col :span="4">手机号:
          <el-input class="filter" v-model="tableFilterData.phone" clearable></el-input>
        </el-col>
        <el-col :span="4">级别:
          <el-select class="filter" v-model="tableFilterData.level">
            <el-option v-for="item in levelList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <div style="float: left;margin-left: 40px">
            <el-button type="primary" icon="el-icon-search" @click="tableFilter()">查询</el-button>
            <el-button icon="el-icon-refresh-right" @click="tableFilterReset()">重置</el-button>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" v-show="level == 1" icon="el-icon-plus" @click="addDistributorDialogStatus = true">
            新建分销商
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div id="content">
      <el-table :data="distributors">
        <el-table-column label="分销商id" prop="distributor_id"></el-table-column>
        <el-table-column label="分销商名称" prop="name"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="上级分销商" prop="parent_name"></el-table-column>
        <el-table-column label="分销商级别" prop="level"></el-table-column>
        <el-table-column fixed="right" width="350px" label="操作">
          <template slot-scope="scope">
            <el-button @click="copyUrl(scope.row.distributor_id)" type="text" size="small">复制链接</el-button>
            <el-button @click="downQrCode(scope.row.distributor_id)" type="text" size="small">下载二维码</el-button>
            <el-button @click="downActive1(scope.row.distributor_id)" type="text" size="small">下载活动页1</el-button>
            <el-button @click="downActive2(scope.row.distributor_id)" type="text" size="small">下载活动页2</el-button>
            <el-button v-show="level == 1" @click="editDistributorDialog(scope.row)" type="text" size="small">修改
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-top: 10px"
          background
          layout="prev, pager, next"
          :total="tableTotal">
      </el-pagination>
    </div>
    <div id="qrcode" v-show="false"></div>
    <div id="dialog">
      <div id="add">
        <el-dialog title="新建分销商" :visible.sync="addDistributorDialogStatus">
          <el-descriptions :column="1" border>
            <el-descriptions-item label="上级分销商">
              <el-select filterable 输入可搜索 v-model="addDistributorData.parent">
                <el-option v-for="item in selectDistributor"
                           :key="item.distributor_id"
                           :label="item.name"
                           :value="item.distributor_id">
                </el-option>
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item label="分销商名称">
              <el-input v-model="addDistributorData.name"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="手机号">
              <el-input v-model="addDistributorData.phone"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="备注">
              <el-input type="textarea" v-model="addDistributorData.remark"></el-input>
            </el-descriptions-item>
          </el-descriptions>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addSubmit">确 定</el-button>
          </div>
        </el-dialog>
      </div>

      <div id="edit">
        <el-dialog title="编辑分销商" :visible.sync="editDistributorDialogStatus">
          <el-descriptions :column="1" border>
            <el-descriptions-item label="上级分销商">
<!--              <el-select filterable placeholder="输入可搜索" v-model="editDistributorData.parent">-->
<!--                <el-option v-for="item in selectDistributor"-->
<!--                           :key="item.distributor_id"-->
<!--                           :label="item.name"-->
<!--                           :value="item.distributor_id">-->
<!--                </el-option>-->
<!--              </el-select>-->
              <el-input v-model="editDistributorData.parent_name" disabled></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="分销商名称">
              <el-input v-model="editDistributorData.name"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="手机号">
              <el-input v-model="editDistributorData.phone"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="密码">
              <el-input v-model="editDistributorData.password"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="备注">
              <el-input type="textarea" v-model="editDistributorData.remark"></el-input>
            </el-descriptions-item>
          </el-descriptions>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="editSubmit">确 定</el-button>
          </div>
        </el-dialog>
      </div>

    </div>
  </div>
</template>

<script>

import QRCode from "qrcodejs2";

export default {
  name: "Distributor",
  data() {
    return {
      distributors: [],
      levelList: [
        {
          label: "全部",
          value: 0
        },
        {
          label: "一级",
          value: 1
        },
        {
          label: "二级",
          value: 2
        },
        {
          label: "三级",
          value: 3
        },
      ],
      tableTotal: 0,
      editDistributorDialogStatus: false,
      addDistributorDialogStatus: false,
      editDistributorData: {
        distributor_id: "",
        parent_name: "",
        phone: "",
        remark: "",
        name: ""
      },
      addDistributorData: {},
      tableFilterData: {
        "distributor_id": "88979028"
      },
      selectDistributor: [],
      level: localStorage.getItem('distributorLevel'),
      h5Url: "https://china-broadnet.zhxxh.cn/v1_0",
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init: function () {
      this.$axios
          .get(this.$httpUrl + 'backend/distributor/list.json', {
            params: {
              "distributor_id": localStorage.getItem('distributorId')
            }
          })
          .then(res => {
            this.tableTotal = res.data.data.count
            this.distributors = res.data.data.list
            this.$axios
                .get(this.$httpUrl + 'backend/distributor/list.json', {
                  params: {
                    "distributor_id": localStorage.getItem('distributorId'),
                    level: 2,
                    step: 999
                  }
                })
                .then(res => {
                  this.selectDistributor = res.data.data.list
                  this.selectDistributor.unshift({
                    "distributor_id": "88979028",
                    "name": "总代理"
                  })
                }, err => {
                  this.$message.error(err.message);
                  this.selectDistributor.unshift({
                    "distributor_id": "88979028",
                    "name": "总代理"
                  })
                })
          }, err => {
            this.$message.error(err.message);
          })

    },
    editDistributorDialog: function (distributor) {
      this.editDistributorData.distributor_id = distributor.distributor_id
      this.editDistributorData.parent_name = distributor.parent_name
      this.editDistributorData.name = distributor.name
      this.editDistributorData.phone = distributor.phone
      this.editDistributorData.remark = distributor.remark
      this.editDistributorDialogStatus = true
    },
    tableFilter: function () {
      this.$axios
          .get(this.$httpUrl + 'backend/distributor/list.json', {
            params: this.tableFilterData
          })
          .then(res => {
            this.tableTotal = res.data.data.count
            this.distributors = res.data.data.list
          }, err => {
            this.$message.error(err.message);
          })
    },
    tableFilterReset: function () {
      this.tableFilterData = {"distributor_id": "88979028"}
      this.tableFilter()
    },
    editSubmit: function () {
      this.$axios
          .post(this.$httpUrl + 'backend/distributor/edit.json', this.editDistributorData)
          .then(res => {
            this.$message.success("修改成功");
          }, err => {
            this.$message.error(err.message);
          })
      this.editDistributorDialogStatus = {}
      this.init()
      this.editDistributorDialogStatus = false
    },
    addSubmit: function () {
      this.$axios
          .post(this.$httpUrl + 'backend/distributor/add.json', this.addDistributorData)
          .then(res => {
            this.$message.success("新建成功");
          }, err => {
            this.$message.error(err.message);
          })
      this.addDistributorData = {}
      this.init()
      this.addDistributorDialogStatus = false
    },
    copyUrl: function (id) {
      let url = this.h5Url + "?distributor_id=" + id;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    downQrCode: function (id) {
      new QRCode('qrcode', {
        width: 132,
        height: 132,
        text: this.h5Url + "?distributor_id=" + id, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
      })
      let myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas');
      let a = document.createElement('a')
      a.href = myCanvas[0].toDataURL('image/png');
      a.download = '二维码';
      a.click()
      this.$message({
        message: "正在进行下载保存",
        type: 'success'
      })
    },
    downActive1: function (id) {
      this.$axios
          .get(this.$httpUrl + 'backend/distributor/download/active.png', {
            responseType: 'blob',
            params: {
              type: 1,
              distributor_id: id
            }
          })
          .then(res => {
            const blob = new Blob([res.data]);//处理文档流
            const fileName = '活动页.jpeg';
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }, err => {
            this.$message.error(err.message);
          })
    },
    downActive2: function (id) {
      this.$axios
          .get(this.$httpUrl + 'backend/distributor/download/active.png', {
            responseType: 'blob',
            params: {
              type: 2,
              distributor_id: id
            }
          })
          .then(res => {
            const blob = new Blob([res.data]);//处理文档流
            const fileName = '活动页.jpeg';
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }, err => {
            this.$message.error(err.message);
          })
    },
  }
}
</script>

<style scoped>
.filter {
  width: 150px !important;
}

#content {
  margin-top: 50px;
}
</style>