<template>
  <div id="app">
  <router-view></router-view>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'App',
  data() {
    return {

    }
  },
}
</script>
<style>
#app{
  height: 100vh;
}
</style>
