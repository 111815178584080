<template>
  <div>
    <div id="header">
      <el-row style="text-align: left">
        <div class="filter-div">
          订单编号:
          <el-input v-model="tableFilterData.order_id" class="filter"></el-input>
        </div>

        <div class="filter-div">
          分销商ID:
          <el-input v-model="tableFilterData.distributor_id" class="filter"></el-input>
        </div>
        <div class="filter-div">
          分销商名称:
          <el-input v-model="tableFilterData.distributor_name" class="filter"></el-input>
        </div>
        <div class="filter-div">
          订单状态:
          <el-select v-model="tableFilterData.order_status" class="filter">
            <el-option
                v-for="item in selectOrderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-row>
      <el-row style="margin-top: 20px;text-align: left">
        <div class="filter-div">
          办理人姓名:
          <el-input v-model="tableFilterData.username" class="filter"></el-input>
        </div>
        <div class="filter-div">
          办理号码:
          <el-input v-model="tableFilterData.order_phone" class="filter"></el-input>
        </div>
        <div class="filter-div">
          办理时间:
          <el-date-picker
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-model="tableFilterData.time">
          </el-date-picker>
        </div>
        <div class="filter-div">
          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px" @click="tableFilter()">查询</el-button>
          <el-button icon="el-icon-refresh-right" @click="tableFilterReset()">重置</el-button>
        </div>
      </el-row>
    </div>
    <div id="content">
      <el-table :data="orders">
        <el-table-column label="订单编号" prop="order_id"></el-table-column>
        <el-table-column label="办理人姓名" prop="username"></el-table-column>
        <el-table-column label="办理号码" prop="order_phone"></el-table-column>
        <el-table-column label="套餐" prop="package_name"></el-table-column>
        <el-table-column label="最低消费" prop="min_consumption"></el-table-column>
        <el-table-column label="合约期(月)" prop="contract_period"></el-table-column>
        <el-table-column label="预存金额" prop="pre_storage"></el-table-column>
        <el-table-column label="订单状态" prop="order_status"></el-table-column>
        <el-table-column label="订单时间" prop="create_time"></el-table-column>
        <el-table-column label="分销商ID" prop="distributor_id"></el-table-column>
        <el-table-column label="分销商名称" prop="distributor_name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="showOrderInfo(scope.row.order_id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-top: 10px"
          background
          layout="prev, pager, next"
          :total="tableTotal">
      </el-pagination>
    </div>

    <el-dialog title="订单详情" :visible.sync="orderInfoDialog">
      <el-descriptions :column="2" border>
        <el-descriptions-item label="订单编号">{{ orderInfo.order_id }}</el-descriptions-item>
        <el-descriptions-item label="用户姓名">{{ orderInfo.username }}</el-descriptions-item>
        <el-descriptions-item label="用户手机号">{{ orderInfo.user_phone }}</el-descriptions-item>
        <el-descriptions-item label="收货省份">{{ orderInfo.user_province }}</el-descriptions-item>
        <el-descriptions-item label="收货地市">{{ orderInfo.user_city }}</el-descriptions-item>
        <el-descriptions-item label="收货区县">{{ orderInfo.user_area }}</el-descriptions-item>
        <el-descriptions-item label="收货地址">{{ orderInfo.user_address }}</el-descriptions-item>
        <el-descriptions-item label="身份证号">{{ orderInfo.user_idcard }}</el-descriptions-item>
        <el-descriptions-item label="订单手机号">{{ orderInfo.order_phone }}</el-descriptions-item>
        <el-descriptions-item label="订单套餐">{{ orderInfo.package_name }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ orderInfo.order_status }}</el-descriptions-item>
        <el-descriptions-item label="分销商">{{ orderInfo.distributor_name }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>

  </div>
</template>

<script>

export default {
  name: "Order",
  data() {
    return {
      orders: [],
      tableTotal: 0,
      tableFilterData: {},
      selectOrderStatus: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "已下单",
          label: "已下单"
        },
      ],
      orderInfo: {},
      orderInfoDialog: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init: function () {
      this.$axios
          .get(this.$httpUrl + 'backend/order/list.json')
          .then(res => {
            this.orders = res.data.data.list
            this.tableTotal = res.data.data.count
          }, err => {
            this.$message.error(err.message);
          })
    },
    tableFilterReset: function () {
      this.tableFilterData = {}
      this.tableFilter()
    },
    tableFilter: function () {
      this.$axios
          .get(this.$httpUrl + 'backend/order/list.json', {
            params: this.tableFilterData
          })
          .then(res => {
            this.orders = res.data.data.list
            this.tableTotal = res.data.data.count
          }, err => {
            this.$message.error(err.message);
          })
    },
    showOrderInfo: function (orderId) {
      this.$axios
          .get(this.$httpUrl + 'backend/order/info.json', {
              params: {order_id: orderId}
          })
          .then(res => {
            this.orderInfo = res.data.data
            this.orderInfoDialog = true
          }, err => {
            this.$message.error(err.message);
          })
    }

  }
}
</script>

<style scoped>
.filter-div{
  float: left;
  margin-left: 20px;
}
.filter {
  width: 150px !important;
}

#content {
  margin-top: 50px;
}
</style>