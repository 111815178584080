<template>
  <div class="login">
    <div class="mylogin" align="center">
      <h4>登录</h4>
      <el-form
          :model="loginForm"
          :rules="loginRules"
          ref="loginForm"
          label-width="0px"
      >
        <el-form-item label="" prop="account" style="margin-top: 50px">
          <el-row>
            <el-col :span="2">
              <span class="el-icon-s-custom"></span>
            </el-col>
            <el-col :span="22">
              <el-input
                  class="inps"
                  placeholder="手机号"
                  v-model="loginForm.account"
              >
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="passWord" style="margin-top: 50px">
          <el-row>
            <el-col :span="2">
              <span class="el-icon-lock"></span>
            </el-col>
            <el-col :span="22">
              <el-input
                  class="inps"
                  type="password"
                  placeholder="密码"
                  v-model="loginForm.passWord"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top: 55px">
          <el-button type="primary" round class="submitBtn" size="medium" @click="submitForm"
          >登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Login",
  data: function () {
    return {
      loginForm: {
        account: "",
        passWord: "",
      },
      loginRules: {
        account: [{required: true, message: "请输入手机号", trigger: "blur"}],
        passWord: [{required: true, message: "请输入密码", trigger: "blur"}],
      },
    }
  },
  methods: {
    submitForm() {
      const userAccount = this.loginForm.account;
      const userPassword = this.loginForm.passWord;
      if (!userAccount) {
        return this.$message({
          type: "error",
          message: "手机号不能为空！",
        });
      }
      if (!userPassword) {
        return this.$message({
          type: "error",
          message: "密码不能为空！",
        });
      }
      this.$axios
          .post(this.$httpUrl + 'backend/login.json', {
            phone: userAccount,
            password: userPassword
          })
          .then(res => {
            if (res.data.status === true) {
              localStorage.setItem('token', res.data.data.token)
              localStorage.setItem('distributorId', res.data.data.distributor_id)
              localStorage.setItem('distributorLevel', res.data.data.level)
              this.$router.replace({
                path: "/home/index"
              })
            } else {
              this.$message.warning(res.data.msg)
            }
          }, err => {
            this.$message.error(err.message)
          })
    },
  },
}
</script>

<style scoped>
.login {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-size: 22px;
  overflow: hidden;
  background-size: cover;
  background-image: url("https://qiniu.zhxxh.cn/china-broadnet/v1_0/img/login_bg.jpg");
  color: #111;
  font-family: "Source Sans Pro";
  position: relative;
}

.mylogin {
  width: 540px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 55%;
  margin-top: 10%;
  padding: 60px;
  /*box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);*/
  box-shadow: -15px 15px 15px #dee1e8;
  opacity: 1;
  /*background: linear-gradient( 230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100% );*/
  background: #fff;
}

.inps .el-input__inner {
  border: none;
  color: #111;
  background-color: #edecec;
  font-size: 18px;
  height: 53px;
}

.submitBtn {
  width: 100%;
  height: 50px;
}
</style>