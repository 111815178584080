<template>
  <div id="home">
    <el-container>
      <el-aside width="200px">
        <img src="./assets/logo.png" width="180px" style="margin-top: 5px">
        <el-menu :default-active="activeIndex" background-color="#D3DCE6" :router="true">
          <el-menu-item class="navi" index="/home/index">首页</el-menu-item>
          <el-menu-item class="navi" index="/home/distributor">分销商管理</el-menu-item>
          <el-menu-item class="navi" index="/home/order">订单管理</el-menu-item>
          <el-menu-item class="navi" index="4" v-if="false" disabled>酬金管理</el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header height="77px">
          <span id="logout" class="upper-right-corner" @click="logout"><i
              class="el-icon-switch-button"></i>退出登录</span>
          <span id="userCenter" class="upper-right-corner" @click="userCenter = true"><i class="el-icon-s-custom"></i>个人中心</span>
        </el-header>
        <el-main>
          <router-view/>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog title="个人中心" :visible.sync="userCenter" @open="openUserCenter()">
      <el-descriptions :column="2" border>
        <el-descriptions-item label="分销商名称">{{ userInfo.name }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ userInfo.phone }}</el-descriptions-item>
        <el-descriptions-item label="分销商级别">{{ userInfo.level }}</el-descriptions-item>
        <el-descriptions-item label="上级分销商">{{ userInfo.parent_name }}</el-descriptions-item>
      </el-descriptions>
      <el-dialog
          width="30%"
          title="修改密码"
          :visible.sync="changePwd"
          append-to-body>
        <el-form :model="pwd">
          <el-form-item label="新密码">
            <el-input v-model="pwd.password" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changePwd = false">取 消</el-button>
          <el-button type="primary" @click="changePwdSubmit()">确 定</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changePwd = true">修改密码</el-button>
        <el-button type="primary" @click="userCenter = false">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
  name: 'Home',
  data() {
    return {
      circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      userCenter: false,
      userInfo: {},
      changePwd: false,
      pwd: {
        password: ""
      },
      activeIndex: "/home/"
    }
  },
  mounted() {
    this.activeIndex = this.$route.path
  },
  methods: {
    openUserCenter: function () {
      this.$axios
          .get(this.$httpUrl + 'backend/distributor/info.json', {
            params: {
              'distributor_id': localStorage.getItem('distributorId')
            }
          })
          .then(res => {
            this.userInfo = res.data.data
          }, err => {
            this.$message.error(err.message)
          })
    },
    logout: function () {
      localStorage.removeItem('token')
      localStorage.removeItem('distributorId')
      localStorage.removeItem('distributorLevel')
      this.$router.replace({
        path: '/login'
      })
    },
    changePwdSubmit: function () {
      if (this.pwd.password.length < 6) {
        this.$message.warning('密码长度必须大约等于6位')
        return
      }
      this.$axios
          .post(this.$httpUrl + 'backend/distributor/edit.json', {
            password: this.pwd.password,
            distributor_id: localStorage.getItem('distributorId')
          })
          .then(res => {
            this.$message.success("修改成功");
          }, err => {
            this.$message.error(err.message);
          })
      this.changePwd = false
    }
  }
}
</script>
<style>
.el-header {
  background-color: #B3C0D1;
  color: #333;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  height: calc(100vh - 80px);
}

.el-menu-item.is-active {
  background-color: #c0c4cc !important;
}

.upper-right-corner {
  font-size: 20px;
  margin-top: 25px;
  float: right;
  cursor: pointer;
}

#userCenter {
  margin-right: 10px;
}

.userInfo {
  font-size: 16px;
  margin: 20px 0 0;
  float: left;
}

.router-link-active {
  text-decoration: none;
  color: yellow;
}

a {
  text-decoration: none;
  color: white;
}

.navi{
  font-size: 18px;
}

.upper-right-corner{
  font-size: 16px;
}
</style>
